<template>
<div class="post-container">
    <vs-dialog v-model="addTagDialog">
        <vs-input block placeholder="Inserisci il nome del tag" v-model="newTag" />
        <vs-button block size="lage" @click="addTag">{{ $t('common.save') }}</vs-button>
    </vs-dialog>
    <div class="top">
        <input type="file" accept="image/jpeg,image/png" ref="fileInputCover" @change="fileChanged" style="display: none;" id="fileInputCover" />
        <img v-if="articleDetails != null && articleDetails.picture_id != 0" :src="baseUri + (user != null && user.is_admin ? '/backoffice' : '') + '/blog/articles/' + articleDetails.id + '/pictures/' + articleDetails.picture_id + '?type=MAIN&t=' + t" style="width: 100%;" />
        <div class="header">
            <div class="sx">
                <h1 v-if="!editingMode" style="margin: 0; display: flex; align-items: center;"> {{ articleDetails != null ? articleDetails.title : '..' }} <img v-if="user != null && user.is_admin" @click="editingMode = true" style="width: 20px; cursor: pointer;" src="../../assets/icons/edit.png" /></h1>
                <vs-input block v-model="articleDetails.title" v-else />
                <p style="margin: 0;" class="light"> {{ articleDetails != null ? (new Date(articleDetails.updated_at)).toLocaleDateString() : '..' }}</p>
            </div>
            <div class="dx" v-if="articleDetails != null">
                <vs-button @click="removeTag(tag)" v-for="tag in articleDetails.tags" :key="'tag-' + tag" dark>#{{ tag }}</vs-button>
                <vs-button dark @click="addTagDialog = true" v-if="editingMode">+</vs-button>
            </div>
        </div>

    </div>

    <template v-if="articleDetails != null">
        <div v-if="!editingMode" class="b-content" v-html="DOMPurify.sanitize(articleDetails.text)"></div>

        <template v-else>
            <vs-input label="SEO Description" block v-model="articleDetails.meta_description" />
            <vs-input label="Slug" block v-model="articleDetails.slug" @keypress="preventInvalidInput">
                <template #message-warn v-if="showSlugWarning">
                    Questo campo non è stato ancora salvato.
                </template>
            </vs-input>

            <vue-editor v-model="articleDetails.text" :useCustomImageHandler="true" @image-added="handleImageAdded"></vue-editor>
            <vs-checkbox v-model="articleDetails.published"> Pubblicato </vs-checkbox>
            <vs-button size="large" @click="editArticle">{{ $t('common.saveChanges').toUpperCase() }}</vs-button>
            <vs-button size="large" dark @click="$refs.fileInputCover.click()">COPERTINA</vs-button>
            <vs-button danger border size="large" :loading="deleting" @click="deletePostEvent">{{ $t('common.delete').toUpperCase() }}</vs-button>
        </template>

    </template>

</div>
</template>

<script>
import {
    VueEditor
} from "vue2-editor";

import {
    apiCall,
    baseUri
} from '../../utils/ApiMiddleware';
import DOMPurify from "dompurify";

export default {
    name: "post",
    components: {
        VueEditor
    },

    props: {
        user: {
            type: Object,
            default: null
        },
        

        deleted: {
            type: Function,
            required: false
        }
    },

    data() {
        return {
            articleDetails: null,
            baseUri,
            editingMode: false,
            DOMPurify,
            t: Date.now(),
            deleting: false,
            addTagDialog: false,
            newTag: '',
            showSlugWarning: false
        }
    },

    watch: {
        addTagDialog(){
            this.newTag = '';
        },

        'articleDetails.title'(){
            // fix mac os weird apostrophe
            this.articleDetails.title = this.articleDetails.title.replaceAll('’', '\'');

            this.generateSlug();
        },

        'articleDetails.slug'(){
            this.showSlugWarning = true;
        }
    },
    methods: {
        preventInvalidInput(e){
            // prevent invalid characters from being typed in the slug input, only allowing numbers, letters (without accent) and dashes
            if(!e.key.match(/[a-z0-9-]/i)){
                e.preventDefault();
            }
        },

        removeTag(tag){
            // if is admin
            if(this.user == null || !this.user.is_admin){
                return;
            }
            this.articleDetails.tags = this.articleDetails.tags.filter(e => e != tag);
        },

        addTag(){
            if(this.articleDetails.tags == null){
                this.articleDetails.tags = [];
            }
                
            this.articleDetails.tags.push(this.newTag);
            this.addTagDialog = false;
        },
        async deletePostEvent(){
            this.deleting = true;
            const response = await apiCall('DELETE', '/backoffice/blog/articles/' + this.articleDetails.id);
            this.deleting = false;
            // if status code is 200, show a success message 
            if (response.status == 200) {
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: 'Articolo eliminato con successo',
                    color: 'success',
                    position: 'top-right'
                });
                this.deleted();
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: 'Impossibile eliminare l\'articolo.',
                    color: 'danger',
                    position: 'top-right'
                });
            }
            
        },

        async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
            console.log(file)
            // An example of using FormData
            // NOTE: Your key could be different such as:
            // formData.append('file', file)
            const response = await apiCall('POST', '/backoffice/blog/articles/' + this.articleDetails.id + '/pictures/ARTICLE', { // ARTICLE
                file
            }, false, 'multipart/form-data');

            if(response.status == 200) {
                Editor.insertEmbed(cursorLocation, 'image', baseUri + '/backoffice/blog/articles/' + this.articleDetails.id + '/pictures/' + response.data.id + '?type=ARTICLE&t=' + Date.now());
                resetUploader();
            } else if(response.status == 413){
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('reviews.new.messages.imageTooLarge'),
                    color: 'danger',
                    position: 'top-right'
                });
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: 'Impossibile aggiungere l\'immagine.',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },

        async getArticle() {
            const loading = this.$vs.loading();
            const response = await apiCall('GET', (this.user != null && this.user.is_admin ? '/backoffice' : '') + '/blog/articles/' + this.$route.params.articleId);
            loading.close();
            if (response.status == 200) {
                this.articleDetails = response.data;
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('blog.messages.cannotLoadPost'),
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },

        generateSlug(){
            // generate slug from title, allowing only numbers, letters (without accents) and dashes
            this.articleDetails.slug = this.articleDetails.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replaceAll(' ', '-').replaceAll(/[^a-z0-9-]/g, '');
        },

        async editArticle() {
            // article title can not contain double quotes
            if (this.articleDetails.title.includes('"')) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: 'Il titolo non può contenere virgolette.',
                    color: 'danger',
                    position: 'top-right'
                });
                return;
            }

            // article meta_description can not contain double quotes
            if (this.articleDetails.meta_description.includes('"')) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: 'La descrizione non può contenere virgolette.',
                    color: 'danger',
                    position: 'top-right'
                });
                return;
            }

            // article tags cannot contain double quotes
            if (this.articleDetails.tags != null) {
                for (let i = 0; i < this.articleDetails.tags.length; i++) {
                    if (this.articleDetails.tags[i].includes('"')) {
                        this.$vs.notification({
                            title: this.$t('common.messages.somethingWentWrong'),
                            text: 'I tag non possono contenere virgolette.',
                            color: 'danger',
                            position: 'top-right'
                        });
                        return;
                    }
                }
            }

            
            var temp = JSON.parse(JSON.stringify(this.articleDetails));
            const loading = this.$vs.loading();
            if(this.articleDetails.published){
                // replace all occurrencies of '/backoffice/blog/articles/' with '/blog/articles/' in article content
                temp.text = temp.text.replaceAll('/backoffice/blog/articles/', '/blog/articles/')
            } else if(!this.articleDetails.text.includes('/backoffice/blog/articles')){
                temp.text = temp.text.replaceAll('/blog/articles/', '/backoffice/blog/articles/')
            }
            const response = await apiCall('PATCH', '/backoffice/blog/articles/' + this.articleDetails.id, temp);
            loading.close();
            if (response.status == 200) {
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: 'Articolo modificato son successo.',
                    color: 'success',
                    position: 'top-right'
                });
                this.articleDetails.text = temp.text;
                this.showSlugWarning = false;
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: 'Impossibile modificare l\'articolo.',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },

        async deleteImage(id, type) {
            const response = await apiCall('DELETE', '/backoffice/blog/articles/' + this.articleDetails.id + '/pictures/' + id + '/' + type);
            if (response.status == 200) {
                return true;
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: 'Impossibile eliminare l\'immagine.',
                    color: 'danger',
                    position: 'top-right'
                });
            }
            return false;
        },

        async fileChanged(e) {

            var file = e.target.files[0];
            // use apiCall to call the upload api passing a payload with the base64 image
            const loading = this.$vs.loading();
            if (this.articleDetails.picture_id != 0) {
                if (await this.deleteImage(this.articleDetails.picture_id, 'MAIN') == false) {
                    loading.close();
                    return;
                }
            }

            const response = await apiCall('POST', '/backoffice/blog/articles/' + this.articleDetails.id + '/pictures/MAIN', { // ARTICLE
                file
            }, false, 'multipart/form-data');
            // if status code is 200 show a success message
            if (response.status == 200) {
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: this.$t('reviews.new.messages.imageAdded'),
                    color: 'success',
                    position: 'top-right'
                });
                this.t = Date.now();
            } else if(response.status == 413){
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('reviews.new.messages.imageTooLarge'),
                    color: 'danger',
                    position: 'top-right'
                });
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('reviews.new.messages.cannotAddImage'),
                    color: 'danger',
                    position: 'top-right'
                });
            }
            loading.close();

        },
    },

    mounted() {
        this.getArticle();
    }
}
</script>

<style scoped>
.sx {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    justify-content: center;

}

h1 {
    font-size: 1.5em;
}

.dx {
    display: flex;
    align-items: center;
    gap: 5px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.post-container {

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
    padding-left: 10vw;
    padding-right: 10vw;
    box-sizing: border-box;

}

.top {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

p,
h3 {
    margin: 0;
}

h3 {
    margin-top: 20px;
}


/* MOBILE */
@media (max-width: 600px) {
    .post-container {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        padding-bottom: 80px!important;
height: unset!important;
        width: 100%!important;
    }

    .header {
        flex-direction: column;
    }

    .dx {
        justify-content: flex-start;
        width: 100%;
    }

}
</style>


<style>
.b-content img {
    max-width: 100%;
}

.post-container .dx button {
    margin: 0;
}
</style>
